:root {
  --color-main: #5aa5b9;
  --color-main-light: #e1f6fb;
  --color-sub: #3f4156;
  --color-sub-light: #51546e;
  --color-text: #737373;
  --color-gray: #8b8b8b;
  --color-light: #e5e7eb;
  --color-light-gray: #767676;
  --color-bg: #f8fafd;
  --color-white: #fffefd;
  --color-white-light: #fafafa;
  --color-success: #5dd693;
  --color-error: #fc4b0b;
  --font-base: 'Poppins', arial, helvetica, 'Segoe UI', roboto, ubuntu, sans-serif;
  --font-monospace: 'Fira Code', 'Courier New', courier, monospace;
  --font-regular: 300;
  --font-medium: 500;
  --font-bold: 700;
  --generic-shadow: 0 1px 3px #00000015;
}

.dark-theme {
  --color-main: #71c6dd;
  --color-sub-light: #3f4156;
  --color-sub: #696d97;
  --color-main-light: #3f4156;
  --color-text: #f5f5f5;
  --color-gray: #c5c5c5;
  --color-light: #313131;
  --color-light-gray: #bbb;
  --color-bg: #2d2d39;
  --color-white: #1f1f1f;
  --color-white-light: #1f1f1f;
}
