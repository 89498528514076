.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--color-sub);
  color: var(--color-white);
  z-index: 100;
  box-shadow: 0 1px 6px 3px #00000015;
}

.header > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dark-theme .header {
  background-color: var(--color-main-light);
}

.header__logo {
  text-transform: lowercase;
  display: flex;
  justify-content: center;
}

.header__logo a {
  text-decoration: none;
  color: #fff;
}

.header__logo img {
  position: relative;
  height: 4.2rem;
  left: -0.6rem;
}

.header__logo h3 {
  display: inline-block;
  margin-left: 0.85rem;
  font-size: 3.2rem;
  font-weight: var(--font-bold);
  color: var(--color-main);
}

.header__menubar {
  font-size: 1.8rem;
  background-color: transparent;
  margin-right: 2.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
}

@media screen and (min-width: 650px) {
  .header__menubar {
    display: none;
  }
}

.header__nav {
  display: flex;
  align-items: center;
}

.nav-icon {
  font-size: 24px;
  position: relative;
  cursor: pointer;
}

.nav-item {
  margin: 10px;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-avatar {
  margin: 10px;
  min-width: 5rem;
}

#user--navigation {
  position: fixed;
  width: 300px;
  top: 60px;
  right: 20px;
  max-height: 60vh;
  overflow-y: scroll;
}

#nav-toggle-icon {
  cursor: pointer;
}

.user-navigation--item {
  padding: 10px;
  border-bottom: 1px solid var(--color-light);
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: var(--color-text);
  text-transform: capitalize;
}

.user-navigation--item:hover {
  background-color: var(--color-light);
}

.user--nav--icon {
  color: var(--color-sub);
  margin-right: 10px;
}

.header-notification--unread {
  font-weight: 500;
}

.header-notification {
  font-weight: 300;
}

.header-notification__name {
  margin-right: 6px;
  font-weight: inherit;
}

.header-notification--read {
  font-weight: 300;
}

.nav-icon--unread {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  right: 0;
  background: var(--color-error);
  border-radius: 50%;
}
