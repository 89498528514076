.container {
  width: 92%;
  max-width: 165rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (min-width: 1000px) {
  .container {
    width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 72%;
  }
}
