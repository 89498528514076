.avatar {
  border-radius: 50%;
  border: 2px solid var(--color-main);
  object-fit: cover;
}

.avatar--xl {
  height: 20rem;
  width: 20rem;
}

.avatar--lg {
  height: 15rem;
  width: 15rem;
}

.avatar--md {
  height: 7rem;
  width: 7rem;
}

.avatar--sm {
  height: 5rem;
  width: 5rem;
}
