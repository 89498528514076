.btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: var(--color-light);
  font-weight: var(--font-medium);
  color: var(--color-sub);
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.6rem;
  outline-width: 0;
  outline-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn:hover {
  opacity: 0.85;
}

.btn i {
  pointer-events: none !important;
}

.btn--main {
  background-color: var(--color-main);
  color: var(--color-white);
}

.btn.btn--main--outline {
  background-color: transparent;
  color: var(--color-main);
  border: 2px solid var(--color-main);
}

.btn--sub {
  background-color: var(--color-sub);
  color: var(--color-white);
}

.btn.btn--sub--outline {
  background-color: transparent;
  color: var(--color-sub);
  border: 2px solid var(--color-sub);
}

.btn--warning {
  background: var(--color-error);
  color: white;
}

.btn--sm {
  font-size: 1.3rem;
  padding: 0.5rem 1.2rem;
}

.btn--md {
  font-size: 1.35rem;
  padding: 0.8rem 2rem;
}

.btn--lg {
  font-size: 1.8rem;
  padding: 0.9rem 4.5rem;
}

.btn--main--link,
.btn--sub--link {
  padding: 0;
  background: transparent;
}

.btn.btn--main--link {
  color: var(--color-main);
}

.btn.btn--main--link i {
  color: var(--color-main) !important;
}

.btn.btn--sub--link {
  color: var(--color-sub);
}

.btn.btn--sub--link i {
  color: var(--color-sub) !important;
}

.btn--main--outline:hover {
  opacity: 1;
  color: var(--color-white);
  background: var(--color-main);
}

.btn--sub--outline:hover {
  opacity: 1;
  color: var(--color-white);
  background: var(--color-sub);
}

.button--spinner {
  border: 3px solid #ddd;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

button:disabled,
button[disabled] {
  border: 1px solid var(--color-gray);
  background-color: var(--color-light);
  color: var(--color-gray);
  cursor: unset;
}

/* Dark Theme */
.dark-theme .btn.btn--main {
  background-color: var(--color-sub);
}

.dark-theme .btn.btn--sub {
  background-color: var(--color-main);
}

.dark-theme .btn,
.dark-theme .btn.btn--sub,
.dark-theme .btn.btn--main {
  color: var(--color-text);
}

/* .dark-theme .btn.btn--main--outline {
  color: var(--color-main) !important;
} */
