* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  color: inherit;
  font-size: inherit;
}

html {
  font-size: 50%;
}

@media only screen and (min-width: 480px) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 62.5%;
  }
}

@media only screen and (min-width: 2100px) {
  html {
    font-size: 75%;
  }
}

body,
.app {
  line-height: 1.6;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--color-text);
  background-color: var(--color-bg);
  min-height: 100vh;
}

code {
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  background-color: var(--color-sub);
  color: var(--color-white);
}

.dark-theme code {
  color: var(--color-text);
}
