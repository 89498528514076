.not-found h2 {
  color: var(--color-text);
  font-size: 6rem;
}

.not-found span img {
  height: 4.2rem;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.not-found__logo {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 0.2s;
}

.not-found h2,
.not-found h3,
.not-found p {
  text-align: center;
}

.not-found h3 {
  font-size: 28px;
}

.not-found {
  text-align: center;
}
