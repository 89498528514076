.input-tags {
  width: 100%;
  border-radius: 0.5rem;
  border: 2px solid var(--color-light);
  padding: 0.6rem 0.8rem;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  cursor: text;
}

.input-tags:focus-within {
  border: 2px solid var(--color-main);
}

.input-tags input {
  border: none;
  font-size: 14px;
  padding: 0.5rem 1rem;
  width: 100%;
}

.input-tags input:focus {
  outline: none;
}

.input-tag-list {
  display: flex;
  column-gap: 4px;
  flex-wrap: wrap !important;
  row-gap: 4px;
}

.input-tag-item {
  white-space: nowrap;
}

.input-tag-item small {
  margin-right: 6px;
}

.input-tag-item i {
  color: var(--color-white);
  cursor: pointer;
}
