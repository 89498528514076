.post--options {
  padding-bottom: 1.5rem;
  line-height: 2;
}

.post__dropmenu {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.post__dropmenu--icon {
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.15em;
}

.dropmenu {
  width: 16em;
  transform-origin: top right;
  transition: all 0.05s ease-in-out 0s;
  transform: scaleY(0);
  top: 2em;
  position: absolute;
  z-index: 10;
}

.dropmenu--show {
  transform: scaleY(1);
}

.dropmenu__menuitem {
  padding: 10px;
  border-bottom: 1px solid var(--color-light);
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: var(--color-text);
  text-transform: capitalize;
}

.dropmenu__menuitem:hover {
  background-color: var(--color-light);
}

.post__dropmenu--navicon {
  color: var(--color-sub);
  margin-right: 10px;
}
