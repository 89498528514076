.author-box {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.author-box__info {
  margin-left: 1rem;
  line-height: 1.2;
}

.author-box--md .author-box__info {
  margin-left: 1.6rem;
  line-height: 1.3;
}

.author-box--lg .author-box__info {
  margin-left: 2.8rem;
  line-height: 1.6;
}

.author-box__name {
  font-size: 1.5rem;
  font-weight: var(--font-medium);
  color: var(--color-sub);
}

.dark-theme .author-box__name {
  color: var(--color-text);
}

.author-box--md .author-box__name {
  font-size: 1.65rem;
}

.author-box--lg .author-box__name {
  font-size: 2.4rem;
}

.author-box__handle {
  font-size: 1.4rem;
  color: var(--color-text);
  font-weight: var(--font-regular);
}

.dark-theme .author-box__handle {
  color: var(--color-main);
}

.author-box--md .author-box__handle {
  font-size: 1.5rem;
}

.author-box--lg .author-box__handle {
  font-size: 1.65rem;
}
