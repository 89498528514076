@keyframes move-in-left {
  0% {
    width: 0%;
    transform: scale(2);
  }

  80% {
    width: 70%;
    transform: scale(1);
  }

  100% {
    width: 100%;
  }
}

@keyframes loading {
  from {
    transform: scale(0, 0);
  }

  to {
    transform: scale(1, 1);
  }
}
