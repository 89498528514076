@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@400&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
:root {
  --color-main: #5aa5b9;
  --color-main-light: #e1f6fb;
  --color-sub: #3f4156;
  --color-sub-light: #51546e;
  --color-text: #737373;
  --color-gray: #8b8b8b;
  --color-light: #e5e7eb;
  --color-light-gray: #767676;
  --color-bg: #f8fafd;
  --color-white: #fffefd;
  --color-white-light: #fafafa;
  --color-success: #5dd693;
  --color-error: #fc4b0b;
  --font-base: 'Poppins', arial, helvetica, 'Segoe UI', roboto, ubuntu, sans-serif;
  --font-monospace: 'Fira Code', 'Courier New', courier, monospace;
  --font-regular: 300;
  --font-medium: 500;
  --font-bold: 700;
  --generic-shadow: 0 1px 3px #00000015;
}

.dark-theme {
  --color-main: #71c6dd;
  --color-sub-light: #3f4156;
  --color-sub: #696d97;
  --color-main-light: #3f4156;
  --color-text: #f5f5f5;
  --color-gray: #c5c5c5;
  --color-light: #313131;
  --color-light-gray: #bbb;
  --color-bg: #2d2d39;
  --color-white: #1f1f1f;
  --color-white-light: #1f1f1f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  color: inherit;
  font-size: inherit;
}

html {
  font-size: 50%;
}

@media only screen and (min-width: 480px) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 62.5%;
  }
}

@media only screen and (min-width: 2100px) {
  html {
    font-size: 75%;
  }
}

body,
.app {
  line-height: 1.6;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--color-text);
  background-color: var(--color-bg);
  min-height: 100vh;
}

code {
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  background-color: var(--color-sub);
  color: var(--color-white);
}

.dark-theme code {
  color: var(--color-text);
}

* {
  font-family: var(--font-base);
}

h1 {
  font-size: 4.8rem;
  font-weight: var(--font-medium);
}

h2 {
  font-size: 3.6rem;
  font-weight: var(--font-bold);
}

h3 {
  font-size: 3.2rem;
  font-weight: var(--font-medium);
}

h4 {
  font-size: 2.8rem;
  font-weight: var(--font-medium);
}

h5 {
  font-size: 2.4rem;
  font-weight: var(--font-medium);
}

h5,
h6 {
  font-weight: var(--font-regular);
}

h6 {
  font-size: 1.8rem;
}

p,
span,
strong {
  font-size: 1.6rem;
  color: var(--color-text);
  font-weight: var(--font-regular);
}

strong {
  font-weight: var(--font-medium);
}

pre > *,
pre > code * {
  font-family: var(--font-monospace) !important;
}

a {
  padding-bottom: 2px;
  text-decoration: none;
  display: inline-block;
  color: var(--color-main);
  font-weight: var(--font-medium);
}

.dark-theme .fas,
.dark-theme .far {
  color: var(--color-text);
}

.container {
  width: 92%;
  max-width: 165rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (min-width: 1000px) {
  .container {
    width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 72%;
  }
}

@-webkit-keyframes move-in-left {
  0% {
    width: 0%;
    -webkit-transform: scale(2);
            transform: scale(2);
  }

  80% {
    width: 70%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    width: 100%;
  }
}

@keyframes move-in-left {
  0% {
    width: 0%;
    -webkit-transform: scale(2);
            transform: scale(2);
  }

  80% {
    width: 70%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes loading {
  from {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }

  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes loading {
  from {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }

  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.contentArea {
  margin-top: 2.5rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  margin-left: auto;
}

.contentArea > *:first-of-type {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}

.contentArea > *:last-of-type {
  display: none;
}

.contentArea > *:first-of-type > *,
.contentArea > *:last-of-type > * {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 650px) {
  .contentArea {
    width: 81%;
  }

  .contentArea > *:first-of-type {
    margin-left: auto;
    -webkit-flex-basis: 85%;
            flex-basis: 85%;
  }
}

@media screen and (min-width: 900px) {
  .contentArea > *:first-of-type {
    -webkit-flex-basis: 90%;
            flex-basis: 90%;
  }
}

@media screen and (min-width: 1150px) {
  .contentArea > *:first-of-type {
    -webkit-flex-basis: 65%;
            flex-basis: 65%;
  }

  .contentArea > *:last-of-type {
    display: block;
    -webkit-flex-basis: 35%;
            flex-basis: 35%;
  }
}

.contentArea--fullWidth {
  width: 100% !important;
}

.contentArea--singleContent > *:first-of-type {
  -webkit-flex-basis: 100% !important;
          flex-basis: 100% !important;
}

.avatar {
  border-radius: 50%;
  border: 2px solid var(--color-main);
  object-fit: cover;
}

.avatar--xl {
  height: 20rem;
  width: 20rem;
}

.avatar--lg {
  height: 15rem;
  width: 15rem;
}

.avatar--md {
  height: 7rem;
  width: 7rem;
}

.avatar--sm {
  height: 5rem;
  width: 5rem;
}

.btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: var(--color-light);
  font-weight: var(--font-medium);
  color: var(--color-sub);
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.6rem;
  outline-width: 0;
  outline-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn:hover {
  opacity: 0.85;
}

.btn i {
  pointer-events: none !important;
}

.btn--main {
  background-color: var(--color-main);
  color: var(--color-white);
}

.btn.btn--main--outline {
  background-color: transparent;
  color: var(--color-main);
  border: 2px solid var(--color-main);
}

.btn--sub {
  background-color: var(--color-sub);
  color: var(--color-white);
}

.btn.btn--sub--outline {
  background-color: transparent;
  color: var(--color-sub);
  border: 2px solid var(--color-sub);
}

.btn--warning {
  background: var(--color-error);
  color: white;
}

.btn--sm {
  font-size: 1.3rem;
  padding: 0.5rem 1.2rem;
}

.btn--md {
  font-size: 1.35rem;
  padding: 0.8rem 2rem;
}

.btn--lg {
  font-size: 1.8rem;
  padding: 0.9rem 4.5rem;
}

.btn--main--link,
.btn--sub--link {
  padding: 0;
  background: transparent;
}

.btn.btn--main--link {
  color: var(--color-main);
}

.btn.btn--main--link i {
  color: var(--color-main) !important;
}

.btn.btn--sub--link {
  color: var(--color-sub);
}

.btn.btn--sub--link i {
  color: var(--color-sub) !important;
}

.btn--main--outline:hover {
  opacity: 1;
  color: var(--color-white);
  background: var(--color-main);
}

.btn--sub--outline:hover {
  opacity: 1;
  color: var(--color-white);
  background: var(--color-sub);
}

.button--spinner {
  border: 3px solid #ddd;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

button:disabled,
button[disabled] {
  border: 1px solid var(--color-gray);
  background-color: var(--color-light);
  color: var(--color-gray);
  cursor: unset;
}

/* Dark Theme */
.dark-theme .btn.btn--main {
  background-color: var(--color-sub);
}

.dark-theme .btn.btn--sub {
  background-color: var(--color-main);
}

.dark-theme .btn,
.dark-theme .btn.btn--sub,
.dark-theme .btn.btn--main {
  color: var(--color-text);
}

/* .dark-theme .btn.btn--main--outline {
  color: var(--color-main) !important;
} */

.card {
  border-radius: 1rem;
  background-color: var(--color-white);
  border: 1.5px solid var(--color-light);
  width: auto;
  height: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
}

.card__link {
  transition: all 0.3s ease-in;
}

.card__link:hover {
  text-decoration: none;
}

.card__body,
.card__header {
  padding: 2rem 2.5rem;
}

.card__header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1.5px solid var(--color-light);
}

.card__headerTitle {
  font-size: 2.2rem;
  font-weight: var(--font-medium);
}

.card__header .card__link:hover {
  text-decoration: underline;
  color: var(--color-main);
}

.card.card--dark {
  background-color: var(--color-light);
}

.card.card--dark .card__header {
  box-shadow: var(--generic-shadow);
}

.dark-theme .card {
  background-color: var(--color-sub-light);
}

.dark-theme .card.card--dark {
  background-color: var(--color-main-light);
}

.form {
  display: block;
}

.form--inline {
  display: inline-block;
}

.form__field {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.form__error {
  color: var(--color-error);
  font-weight: var(--font-medium);
  display: block;
  font-size: 1.4rem;
}

.form__label {
  display: block;
  margin-bottom: 0.8rem;
  color: var(--color-text);
}

.form__label--inline {
  display: inline-block;
  margin-bottom: 0.8rem;
  color: var(--color-text);
}

.form__label--hidden {
  position: absolute;
  text-indent: -9999px;
}

input.input[type='text'],
input.input[type='email'],
input.input[type='password'],
input.input[type='number'],
textarea.input {
  min-width: 24rem;
  width: 100%;
  padding: 1.2rem 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid var(--color-light);
  transition: all 0.3s ease-in-out;
  background-color: var(--color-bg);
}

input.input[type='text']:focus,
input.input[type='email']:focus,
input.input[type='password']:focus,
input.input[type='number']:focus,
input.input[type='text']:active,
input.input[type='email']:active,
input.input[type='password']:active,
input.input[type='number']:active,
textarea.input:focus,
textarea.input:active {
  outline: none;
  border: 2px solid var(--color-main);
}

.form__field--radio,
.form__field--checkbox {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.form__field--radio p,
.form__field--checkbox p {
  margin-right: 3rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--color-text);
}

.form__field--radio input + label,
.form__field--checkbox input + label {
  display: inline-block;
  margin-left: 1.5rem;
  margin-right: 2rem;
  margin-bottom: 0;
  cursor: pointer;
  color: var(--color-text);
}

.input--textarea {
  width: 100%;
  min-height: 14rem;
  resize: none;
}

.input--textarea--sm {
  min-height: 8rem;
}

/* Custom Radio & Checkbox */

.input.input--checkbox,
.input.input--radio {
  position: relative;
  cursor: pointer;
}

.input.input--checkbox::before {
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: rotate(-45deg) scale(0, 0);
          transform: rotate(-45deg) scale(0, 0);
  content: '';
  position: absolute;
  left: 4px;
  top: 1.4px;
  z-index: 1;
  width: 0.8rem;
  height: 0.4rem;
  border: 2.5px solid var(--color-main);
  border-top-style: none;
  border-right-style: none;
}

.input.input--checkbox:checked::before {
  -webkit-transform: rotate(-45deg) scale(1, 1);
          transform: rotate(-45deg) scale(1, 1);
}

.input.input--checkbox::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-white);
  border: 2px solid var(--color-gray);
  cursor: pointer;
}

.input.input--checkbox:checked::after {
  background: var(--color-main-light);
  border-color: var(--color-main);
}

.input.input--radio::before {
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  content: '';
  position: absolute;
  left: 3.5px;
  top: 2.5px;
  z-index: 1;
  width: 0.9rem;
  height: 0.9rem;
  background: var(--color-main);
  border-radius: 50%;
}

.input.input--radio:checked::before {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.input.input--radio::after {
  content: '';
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-white);
  border: 2px solid var(--color-gray);
  border-radius: 50%;
}

.input.input--radio:checked::after {
  background: var(--color-main-light);
}

.form__field--action > *:not(:last-child) {
  margin-right: 1rem;
}

/* dark theme styles */

.dark-theme input.input[type='text']:focus,
.dark-theme input.input[type='email']:focus,
.dark-theme input.input[type='password']:focus,
.dark-theme input.input[type='number']:focus,
.dark-theme input.input[type='text']:active,
.dark-theme input.input[type='email']:active,
.dark-theme input.input[type='password']:active,
.dark-theme input.input[type='number']:active,
.dark-theme textarea.input:focus,
.dark-theme textarea.input:active {
  outline: none;
  border: 2px solid var(--color-main);
}

.tag {
  display: inline-block;
  background-color: var(--color-sub);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  cursor: pointer;
}

.tag > small {
  color: var(--color-white);
}

.tag--outline > small {
  color: var(--color-sub);
}

.dark-theme .tag > small {
  color: var(--color-text);
}

.tag--outline {
  border: 2px solid var(--color-sub);
  background-color: transparent;
  padding: 0.4rem 1rem;
}

.input-tags {
  width: 100%;
  border-radius: 0.5rem;
  border: 2px solid var(--color-light);
  padding: 0.6rem 0.8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  cursor: text;
}

.input-tags:focus-within {
  border: 2px solid var(--color-main);
}

.input-tags input {
  border: none;
  font-size: 14px;
  padding: 0.5rem 1rem;
  width: 100%;
}

.input-tags input:focus {
  outline: none;
}

.input-tag-list {
  display: -webkit-flex;
  display: flex;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
  grid-row-gap: 4px;
  row-gap: 4px;
}

.input-tag-item {
  white-space: nowrap;
}

.input-tag-item small {
  margin-right: 6px;
}

.input-tag-item i {
  color: var(--color-white);
  cursor: pointer;
}

.author-box {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.author-box__info {
  margin-left: 1rem;
  line-height: 1.2;
}

.author-box--md .author-box__info {
  margin-left: 1.6rem;
  line-height: 1.3;
}

.author-box--lg .author-box__info {
  margin-left: 2.8rem;
  line-height: 1.6;
}

.author-box__name {
  font-size: 1.5rem;
  font-weight: var(--font-medium);
  color: var(--color-sub);
}

.dark-theme .author-box__name {
  color: var(--color-text);
}

.author-box--md .author-box__name {
  font-size: 1.65rem;
}

.author-box--lg .author-box__name {
  font-size: 2.4rem;
}

.author-box__handle {
  font-size: 1.4rem;
  color: var(--color-text);
  font-weight: var(--font-regular);
}

.dark-theme .author-box__handle {
  color: var(--color-main);
}

.author-box--md .author-box__handle {
  font-size: 1.5rem;
}

.author-box--lg .author-box__handle {
  font-size: 1.65rem;
}

.Toastify__toast {
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}

.Toastify .Toastify__toast--default {
  color: var(--color-main);
}

.dark-theme .Toastify .Toastify__toast--default {
  background-color: var(--color-sub-light);
}

.Toastify .Toastify__close-button {
  position: absolute;
  top: 55%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 2rem;
}

.Toastify .Toastify__close-button.Toastify__close-button--default > svg {
  fill: var(--color-main);
}

.Toastify .Toastify__progress-bar--default {
  background: var(--color-main);
}

.message {
  padding: 20px;
  border: 1px solid #888;
  border-radius: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.message--success {
  background-color: #f3fdf2;
  border-color: var(--color-success);
  color: var(--color-success);
}

.message--error {
  background-color: #f8d7da;
  border-color: #f5c2c7;
  color: #842029;
}

.message--warning {
  background-color: #fff3cd;
  border-color: #ffecb5;
  color: #664d03;
}

.message--warning p {
  color: #664d03;
}

.loading {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-bg);
}

.loading__logo img {
  height: 10rem;
  margin-bottom: 3.5rem;
}

.loading__loader {
  display: inline-block;
  font-size: 0;
  padding: 0;
}

.loading__loader span {
  vertical-align: middle;
  border-radius: 100%;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  -webkit-animation: loading 0.8s linear infinite alternate;
  animation: loading 0.8s linear infinite alternate;
  background-color: var(--color-main);
}

.loading__loader span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  opacity: 0.6;
}

.loading__loader span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  opacity: 0.8;
}

.loading__loader span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  opacity: 1;
}

.loading__loader span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  opacity: 0.8;
}

.loading__loader span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  opacity: 0.4;
}

/*
* Project: Mumble
* File: Global StyleSheet v0.1.1
*/

/* Fonts */

/* Base Styles */

.custom-spacer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.line-break {
  background-color: var(--color-light);
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

textarea:focus-within {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 3px;
}

#feed-post-form textarea {
  display: block;
  width: 90%;
  margin: 0 auto;
  background-color: var(--color-bg);
  border: none;
  padding: 20px;
  font-size: 18px;
  border-radius: 50px;
  resize: none;
}

#post-btn-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--color-light);
}

#post-btn {
  text-align: right;
}

.post-user-name {
  text-decoration: none;
  color: var(--color-sub-light);
}

.contributor-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.contributor-wrapper:first-child {
  padding-top: 0;
}

.contributor-wrapper:last-child {
  padding-bottom: 0;
}

.contributor-preview {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-align-items: center;
          align-items: center;
}

.contributor-preview a {
  text-decoration: none;
}

.tags-wrapper {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.snippet-wrapper {
  display: -webkit-flex;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.snippet-wrapper a {
  text-decoration: none;
}

.snippet-engagement-count {
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: var(--color-success);
  color: var(--color-text);
  min-width: 35px;
  padding: 5px;
  margin-right: 10px;
}

.snippet-engagement-count p {
  font-size: 1.8rem;
  color: var(--color-bg);
  font-weight: var(--font-medium);
}

.snippet-text {
  color: var(--color-light-gray);
  font-weight: var(--color-medium);
  font-size: 1.4rem;
  line-height: 1.35;
  transition: all 0.3s ease-out;
}

.snippet-text:hover {
  color: var(--color-main);
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #c4d0d3;
}

::-webkit-scrollbar-thumb {
  background-color: #5aa5b9;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4d94a8;
}

.modal-backdrop {
  position: fixed;
  display: none;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 1000;
}

.model-backdrop.active {
  display: -webkit-flex;
  display: flex;
  opacity: 1;
}

.model-backdrop.inactive {
  opacity: 0;
  display: none;
}

.modal-backdrop .mumble-modal {
  background: white;
  border-radius: 6px;
  width: 100%;
  max-width: 600px;
  max-height: 98%;
  padding: 20px;
  margin: 0 15px;
}

.close-modal {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.close-icon {
  font-size: 14px;
  padding: 0;
}

@media (min-width: 400px) {
  .modal-backdrop .mumble-modal {
    margin: 0 25px;
  }

  .close-icon {
    font-size: 16px;
    padding: 2px;
  }
}

@media (min-width: 1000px) {
  .close-icon {
    font-size: 18px;
    padding: 3px;
  }
}

.mumble-modal .modal-header {
  position: relative;
}

.modal-header .modal-close {
  position: absolute;
  right: 0;
}

.mumble-modal .modal-actions {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

/* until we need it, leaving this file */

.feed > .card {
  margin-bottom: 1.5rem;
}

.post-header-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-align-items: center;
          align-items: center;
}

.replying-to-text {
  margin-left: 65px;
}

.remumbled-note {
  margin-bottom: 10px;
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.remumbled-note i {
  margin-right: 1rem;
  color: var(--color-gray);
}

.post-contents {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid var(--color-light);
  -webkit-align-items: center;
          align-items: center;
}

.post-actions-wrapper {
  display: -webkit-flex;
  display: flex;
  padding-top: 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  transition: all 0.1s ease;
}

.post-actions-wrapper i {
  margin: 1rem;
  margin-bottom: 0;
  color: var(--color-gray);
  cursor: pointer;
  font-size: 2rem;
}

.action-wrapper {
  cursor: pointer;
}

.action-wrapper--disabled {
  pointer-events: none;
}

.action-wrapper:hover * {
  color: var(--color-main);
}

.post-action-text {
  font-size: 1.4rem;
  font-weight: var(--font-medium);
}

.post-comment-wrapper {
  cursor: pointer;
}

.post-body {
  font-size: 1.5rem;
  color: var(--color-text);
  font-weight: var(--font-regular);
}

.post-votes {
  padding: 20px;
  text-align: center;
}

.vote-count {
  font-weight: var(--font-medium);
  color: var(--color-success);
  line-height: 0;
  margin: 1rem 0;
}

/* Bolds a voting arrow if user has already voted on a particular post */

.vote-count__negative {
  color: var(--color-gray);
}

.vote-icon {
  cursor: pointer;
  font-size: 3rem;
}

.up-arrow:hover,
.up-arrow.active,
.fa-chevron-up:hover::before {
  color: var(--color-success);
}

.down-arrow:hover,
.down-arrow.active,
.fa-chevron-down:hover::before {
  color: var(--color-error);
}

/* POST COMMENT STYLING */

.post-comments-wrapper {
  margin-top: 1.5rem;
}

.post-comment > div {
  border-radius: 10px;
  padding: 2rem 3rem;
  background-color: var(--color-white-light);
  border: 2px solid var(--color-bg);
}

.post-card--comment {
  -webkit-transform: scale(0.92);
          transform: scale(0.92);
}

.comment__mentioned {
  margin-bottom: 1rem;
  font-size: 1.35rem;
  font-weight: var(--font-medium);
}

.comment__mentioned span {
  font-size: 1.35rem;
  font-weight: var(--font-medium);
  color: var(--color-main);
}

.comment__mentioned span:not(:last-child)::after {
  content: ',';
  margin-right: 0.3rem;
}

.post-meta {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.post-meta span:first-child {
  line-height: 1;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: var(--font-medium);
  font-size: 1.3rem;
  color: var(--color-light-gray);
}

.post--options {
  padding-bottom: 1.5rem;
  line-height: 2;
}

.post__dropmenu {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.post__dropmenu--icon {
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.15em;
}

.dropmenu {
  width: 16em;
  -webkit-transform-origin: top right;
          transform-origin: top right;
  transition: all 0.05s ease-in-out 0s;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  top: 2em;
  position: absolute;
  z-index: 10;
}

.dropmenu--show {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.dropmenu__menuitem {
  padding: 10px;
  border-bottom: 1px solid var(--color-light);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  cursor: pointer;
  color: var(--color-text);
  text-transform: capitalize;
}

.dropmenu__menuitem:hover {
  background-color: var(--color-light);
}

.post__dropmenu--navicon {
  color: var(--color-sub);
  margin-right: 10px;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--color-sub);
  color: var(--color-white);
  z-index: 100;
  box-shadow: 0 1px 6px 3px #00000015;
}

.header > .container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.dark-theme .header {
  background-color: var(--color-main-light);
}

.header__logo {
  text-transform: lowercase;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.header__logo a {
  text-decoration: none;
  color: #fff;
}

.header__logo img {
  position: relative;
  height: 4.2rem;
  left: -0.6rem;
}

.header__logo h3 {
  display: inline-block;
  margin-left: 0.85rem;
  font-size: 3.2rem;
  font-weight: var(--font-bold);
  color: var(--color-main);
}

.header__menubar {
  font-size: 1.8rem;
  background-color: transparent;
  margin-right: 2.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
}

@media screen and (min-width: 650px) {
  .header__menubar {
    display: none;
  }
}

.header__nav {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.nav-icon {
  font-size: 24px;
  position: relative;
  cursor: pointer;
}

.nav-item {
  margin: 10px;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-avatar {
  margin: 10px;
  min-width: 5rem;
}

#user--navigation {
  position: fixed;
  width: 300px;
  top: 60px;
  right: 20px;
  max-height: 60vh;
  overflow-y: scroll;
}

#nav-toggle-icon {
  cursor: pointer;
}

.user-navigation--item {
  padding: 10px;
  border-bottom: 1px solid var(--color-light);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  cursor: pointer;
  color: var(--color-text);
  text-transform: capitalize;
}

.user-navigation--item:hover {
  background-color: var(--color-light);
}

.user--nav--icon {
  color: var(--color-sub);
  margin-right: 10px;
}

.header-notification--unread {
  font-weight: 500;
}

.header-notification {
  font-weight: 300;
}

.header-notification__name {
  margin-right: 6px;
  font-weight: inherit;
}

.header-notification--read {
  font-weight: 300;
}

.nav-icon--unread {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  right: 0;
  background: var(--color-error);
  border-radius: 50%;
}

#search-form {
  margin: 0 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 10rem;
  padding: 0.9rem 1.5rem;
  background-color: var(--color-sub-light);
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dark-theme #search-form {
  color: var(--color-text);
  background-color: var(--color-sub);
}

#search-form input {
  color: var(--color-light);
  background-color: transparent;
  outline: none;
  border: 2px solid transparent;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.dark-theme #search-form > input {
  color: var(--color-text);
}

#search-form:focus-within {
  outline: none;
  border: 2px solid var(--color-main);
}

#search-form input::-webkit-input-placeholder {
  color: var(--color-gray);
}

#search-form input:-ms-input-placeholder {
  color: var(--color-gray);
}

#search-form input::placeholder {
  color: var(--color-gray);
}

.shortcut__Text {
  font-size: 8px;
}

.shortcut__Key {
  font-size: 10px;
  background-color: #303030;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .shortcut__Text {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .shortcut__Text {
    display: none;
  }
}

.notification-title {
  font-weight: 500;
}

.notification-title__name {
  margin-right: 6px;
}

.notification-title__link {
  color: var(--color-main);
}

.sidebarNav {
  position: fixed;
  display: none;
}

@media screen and (min-width: 650px) {
  .sidebarNav {
    display: block;
  }
}

.sidebarNav--full {
  background: var(--color-light);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-animation: move-in-left 0.3s ease-in-out alternate;
          animation: move-in-left 0.3s ease-in-out alternate;
}

.sidebarNav__menu {
  list-style: none;
}

.sidebarNav__menuItem {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.65rem;
  margin-bottom: 3.5rem;
}

.sidebarNav__menuItem > a {
  color: var(--color-text);
  display: block;
}

.sidebarNav__menuItem--disabled > a {
  pointer-events: none;
  color: rgb(141, 141, 141);
}

.sidebarNav__menuItem > a > i.fas {
  display: inline-block;
  margin-right: 2.5rem;
}

.sidebarNav__menuItem > a.active {
  border-radius: 0.5rem;
  color: var(--color-main);
  position: relative;
}

.sidebarNav__menuItem > a.active::before {
  content: '';
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  position: absolute;
  left: -2.5rem;
  top: 1rem;
  z-index: 111;
  background-color: var(--color-main);
}

.sidebarNav__menuItem > a.active > i.fas {
  color: var(--color-main) !important;
}

.sidebarNav__menuItem .nav-icon--unread {
  right: -6px;
  top: 10px;
}

.post-card-placeholder {
  padding: 2.5rem;
}

.post-card-placeholder__header {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
}

.post-card-placeholder__header-author-box {
  padding-left: 10px;
  padding-top: 20px;
  width: 120px;
}

.post-card-placeholder__body {
  margin-bottom: 60px;
}

.post-card-placeholder__actions {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.user-card__profile-stats-wrapper {
  margin: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.user-card__profile-name {
  font-size: 28px;
}

.user-card__profile-pic {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.user-card__profile-summary {
  text-align: center;
}

.user-card__actions {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.user-card__actions button {
  margin-right: 10px;
}

.search-categories {
  height: 160px;
}

.not-found h2 {
  color: var(--color-text);
  font-size: 6rem;
}

.not-found span img {
  height: 4.2rem;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.not-found__logo {
  opacity: 0;
  -webkit-animation: fadeInUp 1s ease-in-out 0s forwards;
          animation: fadeInUp 1s ease-in-out 0s forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.not-found h2,
.not-found h3,
.not-found p {
  text-align: center;
}

.not-found h3 {
  font-size: 28px;
}

.not-found {
  text-align: center;
}

.empty-mailbox__image {
  width: 30%;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

