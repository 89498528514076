.feed > .card {
  margin-bottom: 1.5rem;
}

.post-header-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.replying-to-text {
  margin-left: 65px;
}

.remumbled-note {
  margin-bottom: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.remumbled-note i {
  margin-right: 1rem;
  color: var(--color-gray);
}

.post-contents {
  display: flex;
  border-bottom: 1px solid var(--color-light);
  align-items: center;
}

.post-actions-wrapper {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  transition: all 0.1s ease;
}

.post-actions-wrapper i {
  margin: 1rem;
  margin-bottom: 0;
  color: var(--color-gray);
  cursor: pointer;
  font-size: 2rem;
}

.action-wrapper {
  cursor: pointer;
}

.action-wrapper--disabled {
  pointer-events: none;
}

.action-wrapper:hover * {
  color: var(--color-main);
}

.post-action-text {
  font-size: 1.4rem;
  font-weight: var(--font-medium);
}

.post-comment-wrapper {
  cursor: pointer;
}

.post-body {
  font-size: 1.5rem;
  color: var(--color-text);
  font-weight: var(--font-regular);
}

.post-votes {
  padding: 20px;
  text-align: center;
}

.vote-count {
  font-weight: var(--font-medium);
  color: var(--color-success);
  line-height: 0;
  margin: 1rem 0;
}

/* Bolds a voting arrow if user has already voted on a particular post */

.vote-count__negative {
  color: var(--color-gray);
}

.vote-icon {
  cursor: pointer;
  font-size: 3rem;
}

.up-arrow:hover,
.up-arrow.active,
.fa-chevron-up:hover::before {
  color: var(--color-success);
}

.down-arrow:hover,
.down-arrow.active,
.fa-chevron-down:hover::before {
  color: var(--color-error);
}

/* POST COMMENT STYLING */

.post-comments-wrapper {
  margin-top: 1.5rem;
}

.post-comment > div {
  border-radius: 10px;
  padding: 2rem 3rem;
  background-color: var(--color-white-light);
  border: 2px solid var(--color-bg);
}

.post-card--comment {
  transform: scale(0.92);
}

.comment__mentioned {
  margin-bottom: 1rem;
  font-size: 1.35rem;
  font-weight: var(--font-medium);
}

.comment__mentioned span {
  font-size: 1.35rem;
  font-weight: var(--font-medium);
  color: var(--color-main);
}

.comment__mentioned span:not(:last-child)::after {
  content: ',';
  margin-right: 0.3rem;
}

.post-meta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-meta span:first-child {
  line-height: 1;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: var(--font-medium);
  font-size: 1.3rem;
  color: var(--color-light-gray);
}
