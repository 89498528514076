.form {
  display: block;
}

.form--inline {
  display: inline-block;
}

.form__field {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.form__error {
  color: var(--color-error);
  font-weight: var(--font-medium);
  display: block;
  font-size: 1.4rem;
}

.form__label {
  display: block;
  margin-bottom: 0.8rem;
  color: var(--color-text);
}

.form__label--inline {
  display: inline-block;
  margin-bottom: 0.8rem;
  color: var(--color-text);
}

.form__label--hidden {
  position: absolute;
  text-indent: -9999px;
}

input.input[type='text'],
input.input[type='email'],
input.input[type='password'],
input.input[type='number'],
textarea.input {
  min-width: 24rem;
  width: 100%;
  padding: 1.2rem 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid var(--color-light);
  transition: all 0.3s ease-in-out;
  background-color: var(--color-bg);
}

input.input[type='text']:focus,
input.input[type='email']:focus,
input.input[type='password']:focus,
input.input[type='number']:focus,
input.input[type='text']:active,
input.input[type='email']:active,
input.input[type='password']:active,
input.input[type='number']:active,
textarea.input:focus,
textarea.input:active {
  outline: none;
  border: 2px solid var(--color-main);
}

.form__field--radio,
.form__field--checkbox {
  display: flex;
  align-items: center;
}

.form__field--radio p,
.form__field--checkbox p {
  margin-right: 3rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--color-text);
}

.form__field--radio input + label,
.form__field--checkbox input + label {
  display: inline-block;
  margin-left: 1.5rem;
  margin-right: 2rem;
  margin-bottom: 0;
  cursor: pointer;
  color: var(--color-text);
}

.input--textarea {
  width: 100%;
  min-height: 14rem;
  resize: none;
}

.input--textarea--sm {
  min-height: 8rem;
}

/* Custom Radio & Checkbox */

.input.input--checkbox,
.input.input--radio {
  position: relative;
  cursor: pointer;
}

.input.input--checkbox::before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: rotate(-45deg) scale(0, 0);
  content: '';
  position: absolute;
  left: 4px;
  top: 1.4px;
  z-index: 1;
  width: 0.8rem;
  height: 0.4rem;
  border: 2.5px solid var(--color-main);
  border-top-style: none;
  border-right-style: none;
}

.input.input--checkbox:checked::before {
  transform: rotate(-45deg) scale(1, 1);
}

.input.input--checkbox::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-white);
  border: 2px solid var(--color-gray);
  cursor: pointer;
}

.input.input--checkbox:checked::after {
  background: var(--color-main-light);
  border-color: var(--color-main);
}

.input.input--radio::before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: scale(0, 0);
  content: '';
  position: absolute;
  left: 3.5px;
  top: 2.5px;
  z-index: 1;
  width: 0.9rem;
  height: 0.9rem;
  background: var(--color-main);
  border-radius: 50%;
}

.input.input--radio:checked::before {
  transform: scale(1, 1);
}

.input.input--radio::after {
  content: '';
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-white);
  border: 2px solid var(--color-gray);
  border-radius: 50%;
}

.input.input--radio:checked::after {
  background: var(--color-main-light);
}

.form__field--action > *:not(:last-child) {
  margin-right: 1rem;
}

/* dark theme styles */

.dark-theme input.input[type='text']:focus,
.dark-theme input.input[type='email']:focus,
.dark-theme input.input[type='password']:focus,
.dark-theme input.input[type='number']:focus,
.dark-theme input.input[type='text']:active,
.dark-theme input.input[type='email']:active,
.dark-theme input.input[type='password']:active,
.dark-theme input.input[type='number']:active,
.dark-theme textarea.input:focus,
.dark-theme textarea.input:active {
  outline: none;
  border: 2px solid var(--color-main);
}
